import styled from "styled-components"
import { sendEvent } from "../../../../../../common/ApxEventHandler"
import { NEW_MODULE_TYPES } from "../../../../constants"
import { SET_MODULE_TYPE } from "../../constants"
import { useSelector } from "../../state"

export const HoverDiv = styled.div`
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    ${(props) =>
      props.selected
        ? "box-shadow: 0px 0px 0px 1.5px fuchsia;"
        : "box-shadow: none;"}
  }
`

export const StyledHolder = styled.div`
  user-select: none;
  padding: 0px;
  transition: all 0.1s ease-in-out;
  ${(props) =>
    props.selected
      ? "box-shadow: 0px 0px 1px 1.5px fuchsia;"
      : "box-shadow: none;"}
  &:hover {
    ${(props) =>
      !props.previewMode
        ? "box-shadow: 0px 0px 1px 1.5px fuchsia;"
        : "box-shadow: none;"}
    cursor: pointer;
    background: transparent;
  }
`

export const TextArea = styled.textarea`
  &::placeholder {
    ${(props) => `
      color: ${props.placeholderColor};
      font-family: ${props.fontFamily};
      font-size: ${props.fontSize}px;
      font-style: ${props.fontStyle !== "bold" ? props.fontStyle : "normal"};
      font-weight: ${props.fontStyle === "bold" ? props.fontStyle : "normal"};
    `}
  }
`

export const translateToFontConfig = (text_config) => {
  const { color, font_family, size, style, alignment } = text_config
  return {
    color,
    fontFamily: font_family,
    fontSize: `${size}px`,
    fontStyle: style !== "bold" ? style : "normal",
    fontWeight: style === "bold" ? style : "normal",
    margin: 0,
    padding: 0,
    lineHeight: `${Number(size) + 2}px`,
    textAlign: alignment || "initial",
  }
}

export function QuestionHolder({
  text = "Text",
  bgColor,
  handleModuleSelection,
  selected,
  style = {},
}) {
  return (
    <StyledHolder
      bgColor={bgColor || "#FFFFFF"}
      selected={selected}
      onClick={handleModuleSelection}
    >
      <div
        style={{
          minWidth: 50,
          minHeight: 20,
          ...style,
        }}
      >
        {text}
      </div>
    </StyledHolder>
  )
}

export const onModuleSelection = (dispatch, moduleName, prevModule) => {
  dispatch({
    type: SET_MODULE_TYPE,
    value: moduleName === prevModule ? "" : moduleName,
  })
  sendEvent(moduleName, { isExpanded: !(moduleName === prevModule) })
}

export const getTextConfigFromModuleType = (
  moduleType,
  ui,
  currentStep = -1,
  idx = -1,
  npsSelectedSuggestion = -1,
) => {
  switch (moduleType) {
    case NEW_MODULE_TYPES.RATING_REACTION_TEXT: {
      const reaction =
        ui.survey.q_and_a[currentStep].answer.rating.reactions[idx]

      if (reaction) {
        return {
          ...reaction.text,
          position: reaction?.text_position,
          hasImage: reaction?.enable_image ?? false,
        }
      } else {
        return {}
      }
    }
    case NEW_MODULE_TYPES.RATING_Q_AND_S_QUESTION: {
      const q_and_s = ui.survey.q_and_a[currentStep].answer.rating.q_and_s[idx]

      if (q_and_s) {
        return q_and_s.question.title
      } else {
        return {}
      }
    }
    case NEW_MODULE_TYPES.RATING_Q_AND_S_SUGGESTION: {
      const q_and_s = ui.survey.q_and_a[currentStep].answer.rating.q_and_s[idx]

      if (q_and_s) {
        return {
          text: q_and_s.suggestion.text[npsSelectedSuggestion],
          font: q_and_s.suggestion.font,
          color_active: q_and_s.suggestion.color_active,
          color_inactive: q_and_s.suggestion.color_inactive,
        }
      } else {
        return {}
      }
    }
    case NEW_MODULE_TYPES.SURVEYREQUEST_DESC:
      return ui.survey_request.description
    case NEW_MODULE_TYPES.SURVEYREQUEST_TITLE:
      return ui.survey_request.title
    case NEW_MODULE_TYPES.SURVEYREQUEST_BUTTON0_TEXT:
      return ui.survey_request.buttons_container.buttons[0].text
    case NEW_MODULE_TYPES.SURVEYREQUEST_BUTTON1_TEXT:
      return ui.survey_request.buttons_container.buttons[1].text
    case NEW_MODULE_TYPES.SUCCESSMESSAGE_DESC:
      return ui.success_message.description
    case NEW_MODULE_TYPES.SUCCESSMESSAGE_TITLE:
      return ui.success_message.title
    case NEW_MODULE_TYPES.QUESTION_TEXT:
      return ui.survey.q_and_a[currentStep].question.title
    case NEW_MODULE_TYPES.QUESTION_QUES:
      return ui.survey.q_and_a[currentStep].question.question
    case NEW_MODULE_TYPES.QUESTION_CHOICE_PROMPT:
      return ui.survey.q_and_a[currentStep].question.choice_prompt
    case NEW_MODULE_TYPES.QUESTION_DESC:
      return ui.survey.q_and_a[currentStep].question.description
    case NEW_MODULE_TYPES.HEADER_TITLE:
      return ui.header.title
    case NEW_MODULE_TYPES.HEADER_DESC:
      return ui.header.description
    case NEW_MODULE_TYPES.BUTTON_TEXT:
      return ui.survey.q_and_a[currentStep].footer.buttons[0].text
    case NEW_MODULE_TYPES.PLACEHOLDER:
      return ui.survey.q_and_a[currentStep].answer.input_text.placeholder
    case NEW_MODULE_TYPES.RATING_BUTTON_TEXT:
      return {
        font: ui.survey.q_and_a[currentStep].answer.rating.buttons.font,
        color_active:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.color_active,
        color_inactive:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.color_inactive,
      }
    case NEW_MODULE_TYPES.RATING_REACTION_EMOJI:
      return {
        font: ui.survey.q_and_a[currentStep].answer.rating.buttons.font,
        emoji:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.buttons[idx],
        shadow: ui.survey.q_and_a[currentStep].answer.rating.buttons.shadow,
      }
    case NEW_MODULE_TYPES.OPTION_TEXT: {
      const option = ui.survey.q_and_a[currentStep].answer.choices.list[idx]

      if (option) {
        return {
          text: ui.survey.q_and_a[currentStep].answer.choices.list[idx].value,
          font: ui.survey.q_and_a[currentStep].answer.font,
          color_active:
            ui.survey.q_and_a[currentStep].answer.choices.color_active,
          color_inactive:
            ui.survey.q_and_a[currentStep].answer.choices.color_inactive,
          color_disable:
            ui.survey.q_and_a[currentStep].answer.choices.color_disable,
        }
      } else {
        return {}
      }
    }
    case NEW_MODULE_TYPES.RATING_LABEL_START: {
      return {
        ...ui.survey.q_and_a[currentStep].answer.rating.labels.first_rating
          .text,
        position:
          ui.survey.q_and_a[currentStep].answer.rating.labels.first_rating
            .position,
      }
    }
    case NEW_MODULE_TYPES.RATING_LABEL_END: {
      return {
        ...ui.survey.q_and_a[currentStep].answer.rating.labels.last_rating.text,
        position:
          ui.survey.q_and_a[currentStep].answer.rating.labels.last_rating
            .position,
      }
    }
    default:
      return {}
  }
}
export const getImageConfigFromModuleType = (
  moduleType,
  ui,
  currentStep = 0,
  idx = -1,
) => {
  switch (moduleType) {
    case NEW_MODULE_TYPES.RATING_REACTION_IMAGE:
      return ui.survey.q_and_a[currentStep].answer.rating.reactions[idx].image
    case NEW_MODULE_TYPES.SURVEYREQUEST_IMAGE:
      return ui.survey_request.icon
    case NEW_MODULE_TYPES.SUCCESSMESSAGE_IMAGE:
      return ui.success_message.icon
    default:
      return {}
  }
}

export const getIconBtnConfigFromModuleType = (moduleType, ui) => {
  switch (moduleType) {
    case NEW_MODULE_TYPES.BACK_BUTTON:
      return ui.header.back_button
    case NEW_MODULE_TYPES.CLOSE_BUTTON:
      return ui.header.close_button
    case NEW_MODULE_TYPES.SURVEY_CLOSE_BUTTON:
      return ui.survey.close_button
    case NEW_MODULE_TYPES.SURVEYREQUEST_CLOSEBUTTON:
      return ui.survey_request.close_button
    default:
      return {}
  }
}

export const getBgConfigFromModuleType = (moduleType, ui, currentStep) => {
  switch (moduleType) {
    case NEW_MODULE_TYPES.HEADER:
      return ui.header
    case NEW_MODULE_TYPES.BACKGROUND:
      return ui
    case NEW_MODULE_TYPES.BODY:
      return ui.survey
    case NEW_MODULE_TYPES.FOOTER:
      return ui.survey.q_and_a[currentStep].footer
    case NEW_MODULE_TYPES.SURVEYREQUEST_BACKGROUND:
      return ui.survey_request
    case NEW_MODULE_TYPES.SUCCESSMESSAGE_BACKGROUND:
      return ui.success_message
    case NEW_MODULE_TYPES.SURVEYREQUEST_BUTTONCONTAINER:
      return ui.survey_request.buttons_container
    default:
      return {}
  }
}

export const getSliderConfigFromModuleType = (moduleType, ui, currentStep) => {
  switch (moduleType) {
    case NEW_MODULE_TYPES.SLIDER:
      return {
        thumb_color:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.slider_color,
        ripple_color:
          ui.survey.q_and_a[currentStep].answer.rating.buttons
            .bg_color_inactive,
        rail_active_color:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.bg_color_active,
        rail_inactive_color:
          ui.survey.q_and_a[currentStep].answer.rating.buttons
            .bg_color_inactive,
      }
    default:
      return {}
  }
}

export const getBtnConfigFromModuleType = (moduleType, ui, currentStep) => {
  switch (moduleType) {
    case NEW_MODULE_TYPES.BUTTON:
      return ui.survey.q_and_a[currentStep].footer.buttons[0]
    case NEW_MODULE_TYPES.SURVEYREQUEST_BUTTON0:
      return ui.survey_request.buttons_container.buttons[0]
    case NEW_MODULE_TYPES.SURVEYREQUEST_BUTTON1:
      return ui.survey_request.buttons_container.buttons[1]
    default:
      return {}
  }
}

export const getRadioBtnConfigFromModuleType = (
  moduleType,
  ui,
  currentStep,
  idx = -1,
) => {
  switch (moduleType) {
    case NEW_MODULE_TYPES.RATING_BUTTON:
      return {
        color_active:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.color_active,
        color_inactive:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.color_inactive,
        bg_color_active:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.bg_color_active,
        bg_color_inactive:
          ui.survey.q_and_a[currentStep].answer.rating.buttons
            .bg_color_inactive,
        box_active_color:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.box_model
            .active_color,
        box_inactive_color:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.box_model
            .inactive_color,
        width: ui.survey.q_and_a[currentStep].answer.rating.buttons.width,
        height: ui.survey.q_and_a[currentStep].answer.rating.buttons.height,
        border: ui.survey.q_and_a[currentStep].answer.rating.buttons.border,
        shadow: ui.survey.q_and_a[currentStep].answer.rating.buttons.shadow,
      }
    case NEW_MODULE_TYPES.RATING_REACTION_EMOJI_BACKGROUND:
      return {
        color_active:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.color_active,
        color_inactive:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.color_inactive,
        bg_color_active:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.bg_color_active,
        bg_color_inactive:
          ui.survey.q_and_a[currentStep].answer.rating.buttons
            .bg_color_inactive,
        box_active_color:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.box_model
            .active_color,
        box_inactive_color:
          ui.survey.q_and_a[currentStep].answer.rating.buttons.box_model
            .inactive_color,
        width: ui.survey.q_and_a[currentStep].answer.rating.buttons.width,
        height: ui.survey.q_and_a[currentStep].answer.rating.buttons.height,
        border: ui.survey.q_and_a[currentStep].answer.rating.buttons.border,
        shadow: ui.survey.q_and_a[currentStep].answer.rating.buttons.shadow,
      }
    case NEW_MODULE_TYPES.RADIO:
      return {
        color_active:
          ui.survey.q_and_a[currentStep].answer.choices.radio_color_active,
        color_inactive:
          ui.survey.q_and_a[currentStep].answer.choices.radio_color_inactive,
      }
    case NEW_MODULE_TYPES.RATING_Q_AND_S_SUGGESTION_BG: {
      const suggestion =
        ui.survey.q_and_a[currentStep].answer.rating.q_and_s[idx]

      if (!suggestion) {
        return {}
      }

      return {
        color_active: suggestion.suggestion.bg_color_active,
        color_inactive: suggestion.suggestion.bg_color_inactive,
        margin: suggestion.suggestion.margin,
        padding: suggestion.suggestion.padding,
      }
    }
    default:
      return {}
  }
}

export function IconConfigHolder({
  config,
  moduleType,
  onModuleSelection,
  children,
  parentStyles,
  hasBg = false,
  previewMode = false,
  additionalOnClick = () => {},
}) {
  const { moduleType: storeModuleType } = useSelector()

  return (
    <StyledHolder
      selected={!previewMode && moduleType === storeModuleType}
      previewMode={previewMode}
      style={{
        borderRadius: config?.shape === "circle" ? "50%" : "0px",
        width: config?.width ?? 24,
        height: config?.height ?? 24,
        ...parentStyles,
        color: config?.color,
        backgroundColor:
          (hasBg ? config?.backgroundColor : "transparent") ?? "transparent",
      }}
      onClick={(e) => {
        if (previewMode) {
          additionalOnClick()
          return
        }
        e.stopPropagation()
        onModuleSelection(e, moduleType)
      }}
    >
      <div>{children}</div>
    </StyledHolder>
  )
}

/**
 * @param {Array} list The choices list
 * @returns {Array} Randomized list
 */
export function randomizeChoices(list) {
  const otherOption = list.find((item) => item?.is_other)

  if (otherOption) {
    return [
      ...list
        .filter((item) => !item?.is_other)
        .toSorted(() => Math.random() - 0.5),
      otherOption,
    ]
  }

  return list.toSorted(() => Math.random() - 0.5)
}

export function positionOfHeaderButtonsBasedOnPosition(position, offset) {
  switch (position) {
    case "TL":
      return {
        top: `${offset.top}px`,
        left: `${offset.left}px`,
      }
    default:
      return {
        top: `${offset.top}px`,
        right: `${offset.right}px`,
      }
  }
}
export function positionOfSurveyRequestCloseButtonPosition(position, offset) {
  switch (position) {
    case "TL":
      return {
        top: `${offset.y}px`,
        left: `${offset.x}px`,
      }
    default:
      return {
        top: `${offset.y}px`,
        right: `${offset.x}px`,
      }
  }
}

export const GRADIENT_DIRECTION_ENUM = {
  TO_RIGHT: "to right",
  TO_LEFT: "to left",
}

export function getGradientConfigs(gradient) {
  if (gradient.length !== 2) {
    return null
  }
  const [firstColor, secondColor] = gradient
  const direction =
    firstColor.id < secondColor.id
      ? GRADIENT_DIRECTION_ENUM.TO_RIGHT
      : GRADIENT_DIRECTION_ENUM.TO_LEFT
  const colors = [firstColor.color, secondColor.color]

  return {
    direction,
    offset: {
      firstColorOffset: firstColor.offset,
      secondColorOffset: secondColor.offset,
    },
    colors,
  }
}

export function convertToIntialGradientConfig(gradient) {
  const { direction, offset, colors } = gradient
  const [firstColorOffset, secondColorOffset] = [
    offset.firstColorOffset,
    offset.secondColorOffset,
  ]

  const gradientArray = colors.map((color, id) => {
    return {
      color: color,
      active: id === 0 ? true : false,
      id: (direction === GRADIENT_DIRECTION_ENUM.TO_RIGHT) & (id === 0) ? 1 : 2,
      offset:
        (direction === GRADIENT_DIRECTION_ENUM.TO_RIGHT) & (id === 0)
          ? firstColorOffset
          : secondColorOffset,
    }
  })
  return gradientArray
}

import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
  makePutRequest,
} from "../../../../common/actions"

const UNINSTALL_SETTINGS_API = "uninstall"
const UPDATE_UNINSTALL_SETTINGS_API = "uninstall"

const BUNDLE_IDS_API = "bundle-ids"

export function getUninstallSettings(auth, appId, filters) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makeGetRequest(
    UNINSTALL_SETTINGS_API,
    auth,
    appId,
    filters,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function updateUninstallSettings(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makePostRequest(
    UPDATE_UNINSTALL_SETTINGS_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * @enum {string} Platform
 * @property {string} IOS = "ios"
 * @property {string} ANDROID = "android"
 * @property {string} WEB = "web"
 *
 * @typedef {Object} AddBundleIdDto
 * @property {string} id
 * @property {string} name
 * @property {Platform} platform
 */

/**
 * Add a new bundle ID to the app.
 * @param {Object} auth The auth object.
 * @param {string} appId The ID of the app to which the new bundle ID will be added.
 * @param {AddBundleIdDto} details The details of the new bundle ID.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the bundle ID was added successfully, `false` otherwise.
 */
export function addNewBundleId(auth, appId, details) {
  const onSuccessCallback = () => {
    return true
  }
  const onFailureCallback = () => {
    return false
  }

  return makePostRequest(
    BUNDLE_IDS_API,
    auth,
    appId,
    null,
    details,
    onSuccessCallback,
    onFailureCallback,
    null,
    false,
    true,
  )
}

/**
 * Fetch the bundle IDs of a given app by ID.
 * @param {Object} auth The auth object.
 * @param {string} appId The ID of the app of which the bundle IDs will be fetched.
 */
export function getBundleIds(auth, appId) {
  const onSuccessCallback = (json) => {
    return json ?? []
  }
  const onFailureCallback = (error) => {
    return error ?? { message: "App not found" }
  }

  return makeGetRequest(
    BUNDLE_IDS_API,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback,
    false,
    true,
  )
}

/**
 * Delete a bundle ID from an app by ID.
 * @param {Object} auth The auth object.
 * @param {string} appId The ID of the app of which the given bundle ID will be deleted.
 * @param {string} bundleId The ID of the bundle ID to be deleted.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the bundle ID was deleted successfully, `false` otherwise.
 */
export function deleteBundleId(auth, appId, bundleId) {
  const onSuccessCallback = () => {
    return true
  }
  const onFailureCallback = () => {
    return false
  }

  return makeDeleteRequest(
    BUNDLE_IDS_API + "/" + bundleId,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback,
    true,
  )
}

/**
 * Update the list of bundle IDs of a given app by ID.
 * @param {Object} auth The auth object.
 * @param {string} appId The ID of the app of which the given bundle IDs list will be replaced with.
 * @param {AddBundleIdDto[]} bundleIdList The list of bundle IDs to be updated.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the bundle IDs list was updated successfully, `false` otherwise.
 */
export function updateBundleIdList(auth, appId, bundleIdList) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (error) => {
    return error
  }

  return makePutRequest(
    BUNDLE_IDS_API,
    auth,
    appId,
    null,
    bundleIdList,
    onSuccessCallback,
    onFailureCallback,
    null,
    true,
  )
}

import RowDiv from "../../../../../ReusableComponents/RowDiv"
import {
  EMOJI_ARRAY,
  EMOJI_ARRAY_WITH_FIVE_EMOJI,
  EMOJI_ARRAY_WITH_START_ZERO_AND_END_FIVE,
  EMOJI_ARRAY_WITH_START_ZERO_AND_END_TEN,
  EMOJI_ARRAY_WITH_START_ZERO_AND_END_THREE,
  EMOJI_ARRAY_WITH_THREE_EMOJI,
} from "../../constants"
import stylized_4_5 from "./assets/stylized_4_5.svg"
import sylized_0 from "./assets/sylized_0.svg"
import sylized_1 from "./assets/sylized_1.svg"
import sylized_2 from "./assets/sylized_2.svg"
import sylized_3 from "./assets/sylized_3.svg"
import sylized_4 from "./assets/sylized_4.svg"
import sylized_5 from "./assets/sylized_5.svg"
import sylized_6 from "./assets/sylized_6.svg"
import sylized_7 from "./assets/sylized_7.svg"
import sylized_8 from "./assets/sylized_8.svg"
import sylized_9 from "./assets/sylized_9.svg"
import Emoji from "./components/Emoji"
import { ANSWER_MODES, SURVEY_RATING_STYLE } from "./constants"

export const EMOJI_STYLES = {
  CLASSIC_5: ["128545", "128577", "128529", "128516", "128525"],
  CLASSIC_10: [
    "128545",
    "128534",
    "128547",
    "128577",
    "128533",
    "128529",
    "128578",
    "128512",
    "128515",
    "128516",
    "128525",
  ],
  STYLIZED_5: [sylized_0, sylized_1, sylized_4, sylized_8, sylized_9],
  STYLIZED_10: [
    sylized_0,
    sylized_1,
    sylized_2,
    sylized_3,
    sylized_4,
    stylized_4_5,
    sylized_5,
    sylized_6,
    sylized_7,
    sylized_8,
    sylized_9,
  ],
  NUMBERS_5: [1, 2, 3, 4, 5],
  NUMBERS_10: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
}

export const Colors_5 = {
  1: "rgb(229,81,20)",
  2: "rgb(234,115,29)",
  3: "rgb(247,202,88)",
  4: "rgb(163,207,106)",
  5: "rgb(123,182,93)",
}

export const Colors_10 = {
  0: "rgb(229,81,20)",
  1: "rgb(239,100,33)",
  2: "rgb(234,115,29)",
  3: "rgb(234,143,77)",
  4: "rgb(241,176,82)",
  5: "rgb(248,201,79)",
  6: "rgb(246,222,80)",
  7: "rgb(215,213,90)",
  8: "rgb(183,207,106)",
  9: "rgb(163,207,106)",
  10: "rgb(153,196,135)",
}

export const GenerateEmojiRows = (
  style,
  icons,
  isCanvas = true,
  selectedIcon,
  setSelectedIcon,
) => {
  const [iconSet, flag, isCss] = (() => {
    if (style === SURVEY_RATING_STYLE.CLASSIC)
      return [
        icons === 5 ? EMOJI_STYLES.CLASSIC_5 : EMOJI_STYLES.CLASSIC_10,
        true,
        false,
      ]
    else if (style === SURVEY_RATING_STYLE.STYLIZED)
      return [
        icons === 5 ? EMOJI_STYLES.STYLIZED_5 : EMOJI_STYLES.STYLIZED_10,
        false,
        false,
      ]
    else
      return [
        icons === 5 ? EMOJI_STYLES.NUMBERS_5 : EMOJI_STYLES.NUMBERS_10,
        false,
        true,
      ]
  })()

  const additionalStyles_top_row = {
    marginLeft: isCanvas ? "3px" : "2px",
    marginRight: isCanvas ? "3px" : "2px",
  }
  const additionalStyles_bottom_row = {
    marginLeft: isCanvas ? "5px" : "3px",
    marginRight: isCanvas ? "5px" : "3px",
  }
  if (icons === 5)
    return iconSet.map((each, index) => {
      const border =
        selectedIcon === index + 1
          ? {
              border: "1px solid #017DFD",
              boxSizing: "border-box",
            }
          : isCanvas || flag
            ? {}
            : {
                // filter: "grayscale(100%)"
              }
      return isCss ? (
        <Emoji
          isCanvas={isCanvas}
          index={index + 1}
          isunicodeEmoji={flag}
          styles={{ ...additionalStyles_top_row, ...border }}
          setSelectedIcon={setSelectedIcon}
          isCss={isCss}
          bgcolor={isCss ? Colors_5[index + 1] : ""}
        ></Emoji>
      ) : (
        <Emoji
          code={each}
          isCanvas={isCanvas}
          index={index + 1}
          isunicodeEmoji={flag}
          styles={{ ...additionalStyles_top_row, ...border }}
          setSelectedIcon={setSelectedIcon}
        ></Emoji>
      )
    })
  else {
    return (
      <>
        <RowDiv
          styles={{
            width: isCanvas ? "auto" : "100%",
            justifyContent: "space-between",
            padding: isCanvas ? "0px 10px" : 0,
          }}
        >
          {iconSet.slice(0, 6).map((each, index) => {
            const border =
              selectedIcon === index
                ? {
                    border: "1px solid #017DFD",
                    boxSizing: "border-box",
                  }
                : isCanvas || flag
                  ? {}
                  : {
                      filter: "grayscale(100%)",
                    }
            return isCss ? (
              <Emoji
                isCanvas={isCanvas}
                index={index}
                isunicodeEmoji={flag}
                styles={{ ...additionalStyles_bottom_row, ...border }}
                setSelectedIcon={setSelectedIcon}
                isCss={isCss}
                bgcolor={isCss ? Colors_10[index] : ""}
              ></Emoji>
            ) : (
              <Emoji
                code={each}
                isCanvas={isCanvas}
                index={index}
                isunicodeEmoji={flag}
                styles={{ ...additionalStyles_top_row, ...border }}
                setSelectedIcon={setSelectedIcon}
              ></Emoji>
            )
          })}
        </RowDiv>
        <RowDiv
          styles={{
            width: isCanvas ? "auto" : "100%",
            justifyContent: "space-around",
            padding: isCanvas ? 0 : "0px 20px",
          }}
        >
          {iconSet.slice(6, iconSet.length).map((each, index) => {
            const border =
              selectedIcon === 6 + index
                ? {
                    border: "1px solid #017DFD",
                    boxSizing: "border-box",
                  }
                : isCanvas || flag
                  ? {}
                  : {
                      filter: "grayscale(100%)",
                    }
            return isCss ? (
              <Emoji
                isCanvas={isCanvas}
                index={6 + index}
                isunicodeEmoji={flag}
                styles={{ ...additionalStyles_bottom_row, ...border }}
                setSelectedIcon={setSelectedIcon}
                isCss={isCss}
                bgcolor={isCss ? Colors_10[6 + index] : ""}
              ></Emoji>
            ) : (
              <Emoji
                code={each}
                isCanvas={isCanvas}
                index={6 + index}
                isunicodeEmoji={flag}
                styles={{ ...additionalStyles_bottom_row, ...border }}
                setSelectedIcon={setSelectedIcon}
              ></Emoji>
            )
          })}
        </RowDiv>
      </>
    )
  }
}

export function getEnableColors(value, start, end) {
  const range = end - start + 1

  // Calculate the color step size for each channel (red and green)
  const redStep = 255 / range
  // const greenStep = 255 - redStep

  const index = (value - start) * redStep // Calculate the color index based on the value

  // Calculate the red and green channel values based on the index
  const r = 255 - Math.floor(index)
  const g = Math.floor(index)

  const b = 0 // Set blue channel to 0 for a red-green spectrum

  const hex = `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`
  return hex
}

export function generateNpsArray(start, end, buttons = []) {
  if (start > end) {
    return Array.from({ length: start - end + 1 }, (_, i) => ({
      value: `${start === 0 ? start - i - 1 : start - i}`,
      color: getEnableColors(i + 1, end, start) || "#ccc",
      emoji:
        end === 0 && start === 3
          ? EMOJI_ARRAY_WITH_START_ZERO_AND_END_THREE[start - i]
          : end === 0 && start === 5
            ? EMOJI_ARRAY_WITH_START_ZERO_AND_END_FIVE[start - i]
            : end === 0 && start === 10
              ? EMOJI_ARRAY_WITH_START_ZERO_AND_END_TEN[start - i]
              : start === 3
                ? EMOJI_ARRAY_WITH_THREE_EMOJI[
                    start === 0 ? start - i - 1 : start - i
                  ]
                : start === 5
                  ? EMOJI_ARRAY_WITH_FIVE_EMOJI[
                      start === 0 ? start - i - 1 : start - i
                    ]
                  : EMOJI_ARRAY[start === 0 ? start - i - 1 : start - i],
    }))
  } else {
    return Array.from({ length: end - start + 1 }, (_, i) => ({
      value: `${start === 0 ? i : start + i}`,
      color: getEnableColors(i + 1, start, end) || "#ccc",

      text: {
        text: `${start === 0 ? i : start + i}`,
      },
      image: {
        path: "https://storage.googleapis.com/apx_frontend-assets/stories/electronics.png",
      },
      emoji:
        start === 0 && end === 3
          ? EMOJI_ARRAY_WITH_START_ZERO_AND_END_THREE[i]
          : start === 0 && end === 5
            ? EMOJI_ARRAY_WITH_START_ZERO_AND_END_FIVE[i]
            : start === 0 && end === 10
              ? EMOJI_ARRAY_WITH_START_ZERO_AND_END_TEN[i]
              : end === 3
                ? EMOJI_ARRAY_WITH_THREE_EMOJI[start === 0 ? i : start + i]
                : end === 5
                  ? EMOJI_ARRAY_WITH_FIVE_EMOJI[start === 0 ? i : start + i]
                  : EMOJI_ARRAY[start === 0 ? i : start + i],
    }))
  }
}

export const transformSurveyThemeBasedOnCurrentUI = (
  surveyType,
  templateUi,
  themeUi,
) => {
  const {
    ui: {
      containerConfigs: {
        close_icon: {
          close_icon_color,
          height,
          width,
          close_icon_type,
          close_icon_fill_color,
          close_icon_fill_shape,
        },
        previous_button: {
          enable_previous_btn,
          previous_button_color,
          previous_button_height,
          previous_button_width,
          previous_button_type,
          previous_button_fill_shape,
          previous_button_fill_color,
        },
        progress_bar: {
          enable_progress_bar,
          complation_color,
          incomplation_color,
          bar_height,
        },
        stepper: {
          enable_stepper,
          stepper_type,
          text_configs: {
            stepper_font_size,
            stepper_font_color,
            stepper_font_style,
            stepper_font_family,
          },
        },
      },

      footer_configs: {
        background_color,
        border: { stroke_radius, stroke_color, stroke_width, stroke_style },
      },
      buttonConfigs,
      surveyHeaderConfigs: {
        background_color: headerBgColor,
        border: {
          stroke_color: header_stroke_color,
          stroke_width: header_stroke_width,
          stroke_style: header_stroke_style,
        },
        title: {
          header_title_font_size,
          header_title_font_color,
          header_title_font_style,
          header_title_font_family,
          header_title_font_weight,
          header_title_font_alignment,
        },
        description: {
          header_desc_font_color,
          header_desc_font_size,
          header_desc_font_style,
          header_desc_font_family,
          header_desc_font_weight,
          header_desc_font_alignment,
        },
      },
      surveyBodyConfigs: {
        choice_config: {
          radio_color_active,
          radio_color_inactive,
          choice_layout_type,
          active_bg_color,
          inactive_bg_color,
          active_border_color,
          active_text_color,
          inactive_text_color,
        },
        background_color: surveyBodyBgColor,
        border: {
          stroke_color: surveyBodyStrokeColor,
          stroke_width: surveyBodyStrokeWidth,
          stroke_style: surveyBodyStrokeStyle,
        },
        feedback_box_config: {
          text_box_input_color,
          text_box_color,
          text_box_border_color,
        },
        text_configs: {
          question: {
            question_font_size,
            question_font_color,
            question_font_style,
            question_font_family,
            question_font_weight,
            question_font_alignment,
          },
          description: {
            enable_description,
            survey_body_font_size,
            survey_body_font_color,
            survey_body_font_style,
            survey_body_font_family,
            survey_body_font_weight,
            survey_body_font_alignment,
          },
        },
      },
    },
  } = themeUi

  if (surveyType === "latest") {
    return {
      ...templateUi,
      header: {
        ...templateUi.header,
        back_button: {
          ...templateUi.header.back_button,
          type: previous_button_type,
          color: previous_button_color,
          fill_color: previous_button_fill_color,
          shape: previous_button_fill_shape,
          height: previous_button_height,
          width: previous_button_width,
        },
        border: {
          ...templateUi.header.border,
          color: header_stroke_color,
          width: header_stroke_width,
          style: header_stroke_style,
        },
        close_button: {
          ...templateUi.header.close_button,
          type: close_icon_type,
          color: close_icon_color,
          fill_color: close_icon_fill_color,
          shape: close_icon_fill_shape,
          height: height,
          width: width,
        },
        description: {
          ...templateUi.header.description,
          color: header_desc_font_color,
          alignment: header_desc_font_alignment,
          font: {
            family: header_desc_font_family,
            size: header_desc_font_size,
            style: header_desc_font_style,
            weight: header_desc_font_weight,
          },
        },
        background_color: headerBgColor,
        enable_back_button: enable_previous_btn,
        enable_stepper: enable_stepper,
        stepper: {
          ...templateUi.header.stepper,
          type: stepper_type,
          pagination_text: {
            ...templateUi.header.stepper.pagination_text,
            color: stepper_font_color,
            font: {
              family: stepper_font_family,
              size: stepper_font_size,
              style: stepper_font_style,
              weight: "normal",
            },
          },
        },
        title: {
          ...templateUi.header.title,
          color: header_title_font_color,
          alignment: header_title_font_alignment,
          font: {
            family: header_title_font_family,
            size: header_title_font_size,
            style: header_title_font_style,
            weight: header_title_font_weight,
          },
        },
      },
      enable_progressbar: enable_progress_bar,
      progressbar: {
        ...templateUi.progressbar,
        width: bar_height,
        progress_color: complation_color,
        remaining_color: incomplation_color,
      },
      survey: {
        ...templateUi.survey,
        background_color: surveyBodyBgColor,
        border: {
          ...templateUi.survey.border,
          color: surveyBodyStrokeColor,
          width: surveyBodyStrokeWidth,
          style: surveyBodyStrokeStyle,
        },
        q_and_a:
          templateUi.survey.q_and_a.length > 0
            ? templateUi.survey.q_and_a.map((ui) => {
                return {
                  ...ui,
                  question: {
                    ...ui.question,

                    title: {
                      ...ui.question.title,
                      color: question_font_color,
                      alignment: question_font_alignment,
                      font: {
                        ...ui.question.title.font,
                        size: question_font_size,
                        family: question_font_family,
                        style: question_font_style,
                        weight: question_font_weight,
                      },
                    },
                    description: {
                      ...ui.question.description,
                      enable_description: enable_description,

                      color: survey_body_font_color,
                      alignment: survey_body_font_alignment,
                      font: {
                        ...ui.question.description.font,
                        size: survey_body_font_size,
                        family: survey_body_font_family,
                        style: survey_body_font_style,
                        weight: survey_body_font_weight,
                      },
                    },
                  },
                  answer: {
                    ...ui.answer,
                    choices: {
                      ...ui.answer.choices,
                      border: {
                        ...ui.answer.choices.border,
                        color: active_border_color,
                      },
                      color_active: active_text_color,
                      color_inactive: inactive_text_color,
                      radio_color_active: radio_color_active,
                      radio_color_inactive: radio_color_inactive,
                      bg_color_active: active_bg_color,
                      bg_color_inactive: inactive_bg_color,

                      bg_type: choice_layout_type,
                    },
                    input_text: {
                      ...ui.answer.input_text,
                      ...([ANSWER_MODES.text].includes(
                        ui.answer.answer_mode,
                      ) && {
                        border: {
                          ...ui.answer.input_text.border,
                          color: text_box_border_color,
                        },

                        background_color: text_box_color,
                        text_color: text_box_input_color,
                        placeholder: {
                          ...ui.answer.input_text.placeholder,

                          color: text_box_input_color,
                        },
                      }),
                    },
                  },
                  footer: {
                    ...ui.footer,
                    background_color: background_color,
                    border: {
                      ...ui.footer.border,
                      color: stroke_color,
                      style: stroke_style,
                      radius: stroke_radius,
                      width: stroke_width,
                    },
                    buttons: [
                      {
                        ...ui.footer.buttons[0],
                        type: buttonConfigs[0].button_type,
                        active_color:
                          buttonConfigs[0].active_filled_button_color,
                        inactive_color:
                          buttonConfigs[0].inactive_filled_button_color,
                        border: {
                          ...ui.footer.buttons[0].border,
                          color: buttonConfigs[0].border_color,
                          radius: buttonConfigs[0].corner_radius,
                          style: buttonConfigs[0].border_style,
                          width: buttonConfigs[0].border_width,
                        },

                        text: {
                          ...ui.footer.buttons[0].text,
                          color: buttonConfigs[0].button_text.active_font_color,
                          font: {
                            ...ui.footer.buttons[0].text.font,
                            family: buttonConfigs[0].button_text.font_family,
                            style: buttonConfigs[0].button_text.font_style,
                            size: buttonConfigs[0].button_text.font_size,
                            weight: buttonConfigs[0].button_text.font_weight,
                          },
                        },
                      },
                    ],
                  },
                }
              })
            : [],
      },
    }
  }
  return { ...templateUi }
}
